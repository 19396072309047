/*
 Special styles for Data Grid component - overrides default styling
 */

 a.grid_link {
	color: rgba(176, 0, 0, 1);
}
a.grid_link:hover {
	color: #e57373;
	text-decoration: 'none';
}
a.grid_link:visited {
	color: rgba(176, 0, 0, 1);
}