/* Import Fujitsu fonts */
@font-face {
	font-family: "Fujitsu-Sans-Regular";
	src: url("./assets/FujitsuSansRegular.woff") format("woff");
}
@font-face {
	font-family: "Fujitsu-Sans-Bold";
	src: url("./assets/FujitsuSansBold.woff") format("woff");
}

/* Set up necessary themes and font definitions */
.widget-box{
    width: 100%;
    height: 100%;
}

/* Configure default body fonts, antialiasing and smoothing */
body {
  margin: 0;
  font-family: "Fujitsu-Sans-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Configure fonts for code blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Override switch colour */
.MuiSwitch-switchBase.MuiSwitch-colorSecondary.Mui-checked.Mui-disabled {
  color: rgba(222, 111, 111, 1);
}